import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import { styled, TablePagination } from '@mui/material';
import { useState, useEffect } from 'react';
import PageHeader from 'app/PageHeader';
import Button from 'react-bootstrap/Button';
import Link from '@mui/material/Link';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PeopleOutlineTwoToneIcon from '@material-ui/icons/PeopleOutlineTwoTone';
import UploadModal from './UploadModal';
import axios from 'axios';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';
import { Paths } from '../../../../app/baseApi/baseApi';
import EditModel from './EditModal';
const Container = styled('div')(({ theme }) => ({
  margin: '50px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '50px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
  },
}));
const DisplayNextians = () => {
  const [nextiansDetails, setNextiansDetails] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [isError, setIsError] = useState('');
  const [isDelete, setIsDelete] = useState('');

  const handleEdit = (id) => {
    setSelectedBanner(id);
    setModalShow1(true);
  };
  const [isExpanded, setIsExpanded] = useState([]);
  // Read Action perform Here
  const nextianStudents = async () => {
    try {
      const response = await Paths.EndpointsURL.HomeProudNextians;
      axios({
        url: response,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }).then((record) => {
        setNextiansDetails(record.data.data);
        //console.log(record.data.data);
      });
    } catch (error) {
      setIsError(error.msg);
      console.log(error.msg);
    }
  };

  // Delete Action perform Here
  const handleDelete = async (id) => {
    const confirmed = await confirmDelete();
    if (confirmed) {
      try {
        const deleteResp = Paths.EndpointsURL.DeleteProudNextians + `${id}`;
        const res = await axios.delete(deleteResp);
        handleDeleteResponse(res);
      } catch (error) {
        handleError(error);
      }
    } else {
      nextianStudents();
    }
  };
  const confirmDelete = async () => {
    const { isConfirmed } = await Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure that you want to delete this data?',
      // icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonColor: '#3085d6',
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Confirm',
    });
    return isConfirmed;
  };
  const handleDeleteResponse = (res) => {
    if (res.data.status !== 200 && res.data.success) {
      nextianStudents();
      setIsDelete(res.data.data);
      toast.success(res.data.data, { position: 'top-right' });
    } else {
      toast.error(res.data.msg, { position: 'top-right' });
      console.log(res.data.data);
    }
  };
  const handleError = (error) => {
    console.error('Error deleting data:', error.message);
    toast.error('Error deleting data', { position: 'top-right' });
  };

  const handleToggleClick = (index) => {
    setIsExpanded((prevExpandedItems) => {
      const newExpandedItems = [...prevExpandedItems];
      newExpandedItems[index] = !newExpandedItems[index];
      return newExpandedItems;
    });
  };

  useEffect(() => {
    nextianStudents();
  }, []);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <>
      <Container>
        <PageHeader
          title="Proud Nextians Details"
          subTitle="Home Nextians"
          icon={<PeopleOutlineTwoToneIcon fontSize="large" />}
        />
        <Button variant="primary" onClick={() => setModalShow(true)}>
          Add New
        </Button>
        <UploadModal show={modalShow} onHide={() => setModalShow(false)} />
        <EditModel
          show={modalShow1}
          onHide={() => setModalShow1(false)}
          editProudNextiansData={selectedBanner}
        />
        <br />
        <br />
        <MDBTable align="middle">
          <MDBTableHead>
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">Student Name</th>
              <th scope="col">Image</th>
              <th scope="col">Year</th>
              <th scope="col">Rank</th>
              <th scope="col">Description</th>
              <th scope="col">Actions</th>
            </tr>
          </MDBTableHead>
          {nextiansDetails[0] ? (
            nextiansDetails
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((result, index) => {
                return (
                  <>
                    <MDBTableBody>
                      <tr key={result._id}>
                        <td>
                          <p className="fw-normal mb-1">{result.serialNumber}</p>
                        </td>
                        <td>
                          <p className="fw-normal mb-1">{result.studentName}</p>
                        </td>
                        <td>
                          <img
                            src={result.studentImage}
                            alt=""
                            style={{ width: '45px', height: '45px' }}
                            className="rounded-circle"
                          />
                        </td>
                        <td>
                          <p className="fw-normal mb-1">{result.passYear}</p>
                        </td>
                        <td>
                          <p className="fw-normal mb-1">{result.rank}</p>
                        </td>
                        <td>
                          <p className="fw-normal mb-1">
                            {isExpanded[index]
                              ? result.testimonial
                              : `${result.testimonial.substring(0, 60)}...`}
                          </p>
                        </td>
                        <td>
                          <Link style={{ cursor: 'pointer' }} onClick={() => handleEdit(result)}>
                            <EditIcon />
                          </Link>
                          <Link style={{ cursor: 'pointer' }}>
                            <VisibilityIcon />
                          </Link>

                          <Link
                            style={{ cursor: 'pointer', color: 'red' }}
                            onClick={() => handleDelete(result._id)}
                          >
                            <DeleteIcon />
                          </Link>
                        </td>
                      </tr>
                    </MDBTableBody>
                  </>
                );
              })
          ) : (
            <h5 className="text-red mb-0 mt-4 ">No Data Available</h5>
          )}
        </MDBTable>
        <TablePagination
          sx={{ px: 2 }}
          page={page}
          component="div"
          rowsPerPage={rowsPerPage}
          count={nextiansDetails.length}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[5, 15, 20]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          nextIconButtonProps={{ 'aria-label': 'Next Page' }}
          backIconButtonProps={{ 'aria-label': 'Previous Page' }}
        />
      </Container>
    </>
  );
};

export default DisplayNextians;
